<template>
    <div class="main">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :immediate-check="false"
        >
            <div v-for="(item, index) in list" :key="index" class="item">
                <div class="content">
                    <van-image class="giftIcon" :src="item.gift_icon" />
                    <div class="nameAndPrice">
                        <div class="name">{{ item.gift_name }}</div>
                        <div class="price">{{ item.gift_price }} 金币</div>
                    </div>
                    <div class="fromAndTime">
                        <div class="from">
                            <div style="font-size: 11px; font-family: Microsoft YaHei;
                            font-weight: 400; color: #999999;">来自</div>
                            <div style="font-size: 13px; font-family: Microsoft YaHei;
                            font-weight: 400; color: #333333; margin-left: 3px">{{ item.nick_name }}</div>
                        </div>
                        <div class="time">{{ item.create_time }}</div>
                    </div>
                </div>
                <div class="borderBottom"></div>
            </div>
        </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'

export default {
    data() {
        return {
            page: 1,
            page_size: 20,

            refreshing: false,

            loading: false,
            finished: false,

            list: [],
        }
    },
    methods: {
        onRefresh() {
            this.page = 1
            this.list = []
            this.initData()
        },
        onLoad() {
            this.page++
            this.initData()
        },
        initData() {
            doRequest('/gift/record', {
                page: this.page,
                page_size: this.page_size,
            }, this.$route.query).then(res => {
                this.list = this.list.concat(res.list)
                this.loading = false
                this.refreshing = false
                if (res.list.length < this.page_size) {
                    this.finished = true
                }
            })
        },
    },
    mounted() {
        this.initData()
    }
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 375px;
}

.item {
    width: 360px;
    height: 110px;
    display: flex;
    flex-direction: column;
}

.item .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.item .content .giftIcon {
    width: 69px;
    height: 69px;
    margin-left: 7px;
    background: #FFF3EA;
}

.item .content .nameAndPrice {
    display: flex;
    height: 69px;
    width: 100px;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 10px;
}

.item .content .nameAndPrice .name {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.item .content .nameAndPrice .price {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
}

.item .content .fromAndTime {
    display: flex;
    height: 69px;
    margin-left: 50px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
}

.item .content .fromAndTime .from {
    display: flex;
    align-items: center;
}

.item .content .fromAndTime .time {
    font-size: 11px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
}

.item .borderBottom {
    width: 360px;
    border-bottom: 1px solid #DDDDDD;
    margin-left: 7px;
}
</style>